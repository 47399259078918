import React from 'react';
import { 
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import Navigation from '../Navigation';
import JsonTools from '../JsonTools';
import Portfolio from '../Portfolio';
import TransitBoard from '../TransitBoard';

import * as ROUTES from '../../constants/routes';

const App = () => (
  <Router>
    <Navigation/>
    <hr/>
    <Route exact path={ROUTES.LANDING} component={Portfolio}/>
    <Route path={ROUTES.TRANSIT_BOARD} component={TransitBoard}/>
    <Route path={ROUTES.JSON_TOOLS} component={JsonTools}/>

  </Router>
);

export default App;