import React, { Component } from 'react';


class TransitBoard extends Component {
  constructor(props){
    super(props);

    this.state = {
    error: null,
    isLoaded: false,
    resultSet: null,
    currentTime: new Date().toLocaleString(),
    location: null,
    userLocation: null,
    stopIds: [],
    currentStop: 0
    };
  }

  componentDidMount() {
    this.getLocation();
    this.intervalId = setInterval(
      () => this.tick(), 1000
    )
    // this.arrivalInterval = setInterval(
    //   () => this.arrivalTime(), 10000
    // )
  }

  componentWillUnmount() {
    clearInterval(this.intervalId, this.arrivalInterval);
  }

  tick() {
    this.setState({
      currentTime: new Date().toLocaleString() 
    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  setPosition = (position) => {
    this.setState({
      userLocation: position.coords
    })
    this.getLocalStops();
  }

  getLocalStops() {
    fetch("https://developer.trimet.org/ws/V1/stops/ll/" + this.state.userLocation.latitude + "," + this.state.userLocation.longitude + "/appID/D65E22C52145706EA4192471E/json/true")
    .then(res => res.json())
    .then(
      (result) => {
        
        var locIds = []
        result.resultSet.location.forEach(function(location) {
          locIds.push(location.locid);
        });
        this.setState({
          stopIds: locIds
        })
        this.arrivalTime();
        console.log("LOCIDS");
        console.log(locIds);
      },
      (error) => {
        console.log(error);
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  nextStop = () => {
    if (this.state.currentStop === this.state.stopIds.length - 1) {
      this.setState({
        currentStop: 0
      });
    } else {
      this.setState({
        currentStop: this.state.currentStop + 1
      });
    }
    this.arrivalTime()
  }

  previousStop = () => {
    if (this.state.currentStop === 0) {
      this.setState({
        currentStop: (this.state.stopIds.length - 1)
      });
    } else {
      this.setState({
        currentStop: this.state.currentStop - 1
      });
    }
    this.arrivalTime()
  }

  arrivalTime() {
    console.log(this.state.stopIds[this.state.currentStop]);
    fetch("https://developer.trimet.org/ws/v2/arrivals/locIDs/" + this.state.stopIds[this.state.currentStop] + "/appID/D65E22C52145706EA4192471E")
    .then(res => res.json())
    .then(
      (result) => {
        result.resultSet.arrival.forEach(function(ar) {
          ar.minutesLeft = Math.floor((ar.scheduled - new Date()) / 60000) + " minutes";
          ar.scheduled = new Date(ar.scheduled).toLocaleString();
        })
        this.setState({
          isLoaded: true,
          resultSet: result,
          location: result.resultSet.location[0]
        })
        console.log(this.state.resultSet);
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  }

  render() {
    const { error, isLoaded, resultSet, currentTime, location, userLocation } = this.state;

    if(error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Loading...</div>
    } else {
      return (
        <div>
          <button onClick={this.previousStop}>Previous Stop</button> <button onClick={this.nextStop}>Next Stop</button>
          <h1>
            {currentTime}
          </h1>
          <h1>
            {userLocation.latitude} | {userLocation.longitude}
          </h1>
          <h2>{userLocation.accuracy}</h2>
          <h1>
            {location.desc} | {location.dir} | {location.id}
          </h1>
          <h3>
            {location.lat} | {location.lng}
          </h3>
          {resultSet.resultSet.arrival.map(arrival => (
            <div>
              <h2>
                {arrival.fullSign}
              </h2>
              {/* <h2>
                {arrival.scheduled}
              </h2> */}
              <h3>
                {arrival.minutesLeft}
              </h3>
            </div> 
          ))}
        </div>
      );
    }
  } 
}

export default TransitBoard;
